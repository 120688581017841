import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "menuMobile"
  ]

  connect() {
    // Add transition end listener
    this.menuMobileTarget.addEventListener('transitionend', (e) => {
      if (e.propertyName === 'opacity' && !this.menuMobileTarget.classList.contains('opacity-100')) {
        this.menuMobileTarget.classList.add('hidden')
      }
    })
  }

  toggleOverlay() {
    if (this.menuMobileTarget.classList.contains("hidden")) {
      // Opening the menu
      this.menuMobileTarget.classList.remove("hidden")
      // Use requestAnimationFrame to ensure the hidden removal takes effect
      requestAnimationFrame(() => {
        this.menuMobileTarget.classList.add("opacity-100")
      })
    } else {
      // Closing the menu
      this.menuMobileTarget.classList.remove("opacity-100")
    }

    //this.toggleMenu()
    document.body.classList.toggle("overflow-hidden")
  }

  checkSizeOverlay() {
    // used for the default overlay mobile menu
    if(window.innerWidth > 768 && !this.menuMobileTarget.classList.contains("hidden")){
      this.toggleOverlay()
    }
  }

  toggleOffcanvas() {
    this.menuMobileTarget.classList.toggle('-translate-x-full')
    document.body.classList.toggle("overflow-hidden")
  }

  checkSizeOffcanvas() {
    // used for the OffCanvas menu
    if(window.innerWidth > 768 && !this.menuMobileTarget.classList.contains("-translate-x-full")){
      this.toggleOffcanvas()
    }
  }


}
