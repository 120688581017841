import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "previous", "next", "title"]
  static values = { transitionInterval: Number }

  initialize() {
    const images = this.imageTargets

    if (images.length > 1) {
      this.startCarousel(images)
    }

    // Ensure title fades in
    this.titleTarget.classList.add('transition-opacity', 'duration-[4000ms]', 'ease-out', 'opacity-100')
  }

  startCarousel(images) {
    let currentImage = 0;
      setInterval(() => {
        this.fadeOutElement(images[currentImage])
        currentImage = (currentImage + 1) % images.length
        this.fadeInElement(images[currentImage])
      }, this.transitionIntervalValue)
  }

  fadeOutElement(element) {
    element.classList.remove("opacity-100")
    element.classList.add("opacity-0")
  }

  fadeInElement(element) {
    element.classList.remove("opacity-0")
    element.classList.add("opacity-100")
  }
}
