import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "topbar",
    "middlebar",
    "bottombar",
    "button"
  ]

  static outlets = [
    "nav"
  ]

  connect() {}

  animate(event) {
    const animationStyle = event.params['animationStyle']
    const menuReveal = event.params['menuReveal']

    switch(animationStyle) {
      case 'cross_three':
        this.animate_cross_three()
        break
      case 'cross_two':
        this.animate_cross_two()
        break
      case 'pulse':
        this.animate_pulse()
        break
    }

    switch(menuReveal) {
      case 'offcanvas':
        this.navOutlet.toggleOffcanvas()
        break
      case 'overlay':
        this.navOutlet.toggleOverlay()
        break
    }

    this.toggle_hamburger_text_color()
  }

  animate_cross_three(){
    this.topbarTarget.classList.toggle("-translate-y-2")
    this.topbarTarget.classList.toggle("rotate-45")
    this.middlebarTarget.classList.toggle("opacity-0")
    this.bottombarTarget.classList.toggle("translate-y-2")
    this.bottombarTarget.classList.toggle("-rotate-45")
  }

  animate_cross_two(){
    this.topbarTarget.classList.toggle("-translate-y-1")
    this.topbarTarget.classList.toggle("rotate-45")
    this.bottombarTarget.classList.toggle("translate-y-1")
    this.bottombarTarget.classList.toggle("-rotate-45")
  }

  animate_pulse(){
    this.topbarTarget.classList.toggle("w-8")
    this.topbarTarget.classList.toggle("w-16")
    this.middlebarTarget.classList.toggle("w-14")
    this.middlebarTarget.classList.toggle("w-16")
    this.bottombarTarget.classList.toggle("w-12")
    this.bottombarTarget.classList.toggle("w-16")
  }

  toggle_hamburger_text_color(){
    this.buttonTarget.classList.toggle("text-navlink")
    this.buttonTarget.classList.toggle("text-navlink-mobile")
  }

}
