import "@hotwired/turbo-rails"
import { application } from "./controllers/application"
import ahoy from "ahoy.js"


// Only include the specific javascript used on the client websites
// Lightbox and Nav

import CarouselController from "./controllers/carousel_controller.js"
application.register("carousel", CarouselController)

import FadeScrollController from "./controllers/fade_scroll_controller.js"
application.register("fade-scroll", FadeScrollController)

import HamburgerController from "./controllers/hamburger_controller.js"
application.register("hamburger", HamburgerController)

import LightboxController from "./controllers/lightbox_controller.js"
application.register("lightbox", LightboxController)

import NavController from "./controllers/nav_controller.js"
application.register("nav", NavController)

import PreloaderController from "./controllers/preloader_controller.js"
application.register("preloader", PreloaderController)

// TailwindCSS Intersect for animations
import { Observer } from 'tailwindcss-intersect';
Observer.start();
