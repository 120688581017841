import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"]

  connect() {

    this.showPreloader()
  }

  showPreloader() {

    this.contentTarget.classList.add('transition-opacity', 'duration-[1500ms]', 'ease-in', 'opacity-100')

    const FADE_OUT_INTERVAL = 2500

    setInterval(() => {
      this.fadeOutPreloader()
    }, FADE_OUT_INTERVAL)
  }

  fadeOutPreloader() {
    const preloader = this.element;
    // Add fade-out animation
    preloader.classList.add('transition-opacity', 'duration-[2500ms]', 'ease-in-out', 'opacity-0')
    preloader.classList.remove("opacity-100")

    // Remove the preloader from the DOM after the animation is complete
    preloader.addEventListener('transitionend', () => {
      preloader.remove();
    }, { once: true });
  }
}
